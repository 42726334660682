<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div>
        <h6 class="title-h6">{{$t('processes.linked_wallets')}}</h6>
        <div class="row q-col-gutter-md">
          <add-a-wallet v-if="currentRecrutementProcess && currentRecrutementProcess.wallets" @walletsUpdated="updateWallet" v-model="currentRecrutementProcess.wallets" :wallets="currentRecrutementProcess.wallets" class="col"/>
          <add-a-wallet v-else @walletsUpdated="updateWallet" class="col"/>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6">{{$t('processes.annonce')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <!-- <base-input-text v-bind="formInputProps('label')" :color="color"
              v-model="formData.label" ref="label" /> -->
            <base-input-date class="q-px-sm row" v-bind="formInputProps('date_publication')" :color="color"
              v-model="formData.date_publication" ref="label" />
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_ouverture_annonce')" :color="color"
                v-model="formData.date_ouverture_annonce" ref="label" />
              <base-input-date class="col" :label="$t('processes.fields.date_cible_reorganisation.label')" :color="color"
                v-model="formData.date_cible" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-text class="col" placeholder="Renseigner" v-bind="formInputProps('secteur_geographique')" :color="color"
                v-model="formData.secteur_geographique" ref="label" />
              <base-input-text class="col" placeholder="Remplir" v-bind="formInputProps('adresse')" :color="color"
                v-model="formData.adresse" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col q-my-none" v-bind="formInputProps('date_rappel')" :color="color"
                v-model="formData.date_rappel" ref="label" />
              <div class="col q-my-none">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.green_light.label')}}</q-item-label>
                <q-toggle class="" v-model="formData.feu_vert" ref="is_red"
                  :color="color"
                />
              </div>
            </div>
            <q-item-label class="q-mt-lg" :color="color"><span class="add_process_on_required_label">{{$t('processes.add_process_on_required')}}</span></q-item-label>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6">{{$t('processes.linked_processes')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="row q-col-gutter-md">
          <div class="q-ma-none q-mt-sm col-6">
            <div v-if="linkedSuiviDeDepart" class="row full-width q-col-gutter-md q-pt-sm">
              <base-card @menuClick="onMenuClick" class="col-12" :actions="linkedSuiviDeDepart.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="playlist_add_check" />
                <q-item-label @click="$router.push({ name: 'suivi-des-departs-form', params: { id: linkedSuiviDeDepart.id } })" class="cursor-pointer q-mb-md"><strong>{{$t('processes.suivi_de_depart')}}</strong></q-item-label>
                <div class="row">
                  <div class="col" v-for="obj in linkedSuiviDeDepart.wallets" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="account_balance_wallet" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.NOMPTF}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-for="obj in linkedSuiviDeDepart.agents" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="accessibility" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.TITULAIRE}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </base-card>
            </div>
              <div class="q-ma-none q-mt-sm col-6" v-else>
                <search-box v-if="searchBoxActivated"
                  @input="linkSuiviDeDepart"
                  model_type="depart"
                  :label="$t('processes.fields.process.label')"
                  :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
                  :color="color" />
                <q-btn icon="add_circle" v-else :color="color" class="add-process-card full-width" size="md" outline @click="searchBoxActivated = true">
                  {{$t('processes.link_process')}}
                </q-btn>
              </div>
            </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6">{{$t('processes.candidat')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="column q-col-gutter-md">
          <q-table flat wrap-cells v-if="currentRecrutementProcess"
            :data="candidatDatas"
            :columns="candidatColumns"
            row-key="id"
            :loading="loading"
            binary-state-sort>
            <q-tr slot="body" slot-scope="props" :props="props"
              @click.native="$router.push({ name: 'candidat-fiche', params: { id: props.row.id, processId: $route.params.id } })"
              class="cursor-pointer">
              <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
                <div v-if="col.value" v-html="col.value" />
              </q-td>
            </q-tr>
          </q-table>
          <div class="row justify-end">
            <div class="col col-3 q-py-sm q-px-md q-gutter-sm">
              <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!isUpdate" @click="addCandidat" icon-right="add_circle">{{$t('processes.add_candidat')}}</q-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import addAWallet from '../shared/addAWallet'

export default {
  mixins: [BaseForm],
  components: { addAWallet },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      relatedSujets: [],
      searchBoxActivated: false,
      linkedSuiviDeDepart: null,
      isUpdate: this.$route.params.id
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentRecrutementProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),

    label () {
      return !this.$route.params.id ? this.$t('instances.add_instance') : this.$t('instances.edit_instance')
    },

    candidatDatas () {
      if (!this.currentRecrutementProcess || !this.currentRecrutementProcess.candidats.length) return []
      return this.currentRecrutementProcess.candidats.map(({ id, nom, statut }) => ({ id, nom, statut }))
    },

    candidatColumns () {
      if (!this.currentRecrutementProcess || !this.currentRecrutementProcess.candidats.length) return []
      else {
        const fields = ['nom', 'statut']

        return fields.map(key => {
          let label = this.$t(`candidat.fields.${key}.label`)
          let align = 'left'
          let autoWidth = false
          let formatFn = val => {
            console.log(val)
            return val
          }

          if (key === 'statut') {
            formatFn = val => {
              const status = {
                BROUILLON: 'Brouillon',
                EN_COURS: 'En cours',
                SHORTLIST: 'Shortlist',
                RETENU: 'Retenu',
                TERMINE: 'Terminé',
                ABANDONNE: 'Abandonné',
                CANDIDAT: 'Candidat'
              }
              return status[val]
            }
            align = 'center'
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: false,
            autoWidth: autoWidth
          }
        })
      }
    }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'recrutement'
        if (!newVal || !this.currentRecrutementProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === this.currentRecrutementProcess[id]) : this.currentRecrutementProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set wallets data
          let walletsMapped = this.currentRecrutementProcess.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          }))
          this.formData.related_objects.push(...walletsMapped)

          // Set candidats data
          let candidatsMapped = this.currentRecrutementProcess.candidats.map(candidat => ({
            model_type: 'candidat',
            model_id: candidat.id
          }))
          this.formData.related_objects.push(...candidatsMapped)

          // Set departs data
          if (this.currentRecrutementProcess.departs && this.currentRecrutementProcess.departs.length) {
            let suiviDeDepart = this.currentRecrutementProcess.departs[0]
            this.linkedSuiviDeDepart = {
              id: suiviDeDepart.id,
              type: 'depart',
              wallets: suiviDeDepart.wallets,
              agents: suiviDeDepart.agents,
              actions: [
                { label: this.$t(`processes.unlink_depart`), payload: { name: `unlink-depart`, params: { id: suiviDeDepart.id } } }
              ]
            }
            this.formData.related_objects.push({
              model_type: 'depart',
              model_id: suiviDeDepart
            })
          }
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_de_recrutement'), to: { name: 'suivi-de-recrutement-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-de-recrutement-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_recrutement'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-de-recrutement-form', label: 'Informations', to: { name: 'suivi-de-recrutement-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'recrutement' }).then(() => {
        store.dispatch('processes/fetchFormFields', { type: 'recrutement', model_id: currentRoute.params.id })
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'recrutement')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    linkSuiviDeDepart (linkedObj) {
      this.searchBoxActivated = false
      this.linkedSuiviDeDepart = {
        id: linkedObj.value.id,
        type: 'depart',
        wallets: linkedObj.value.wallets,
        agents: linkedObj.value.agents,
        actions: [
          { label: this.$t(`processes.unlink_depart`), payload: { name: `unlink-depart`, params: { id: linkedObj.value.id } } }
        ]
      }
      this.formData.related_objects.push({
        model_type: 'depart',
        model_id: linkedObj.value.id
      })
    },
    onMenuClick ({ name, params }) {
      if (name === 'unlink-depart') {
        this.linkedSuiviDeDepart = null
        this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'depart')
      }
    },
    addCandidat () {
      this.save().then(() => {
        this.$router.push({ name: 'suivi-de-recrutement-form-candidat', params: { processId: this.$route.params.id } })
      })
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-de-recrutement-form', params: { id } })
    },
    copyAnnonce () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },
    onSujetSelect (sujet) {
      this.relatedSujets.push({ model_type: 'sujet', model_id: sujet.id, ...sujet })
      this.isFormOpen = false
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'recrutement', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .add-process-card
    min-height 125px
  .add_process_on_required_label
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
